$(function () {
    $('.datepicker').datepicker({
        format: 'dd/mm/yyyy',
        autoclose: true,
        todayHighlight: true
    });

    function updateCheckList(id, isChecked, dateFrom, dateTo) {
        const studentId = $('#student-check-list').val();
        $.ajax({
            url: Routing.generate('service_student_checklist_update'),
            method: 'POST',
            data: {
                id: id,
                isChecked: isChecked,
                dateFrom: dateFrom,
                dateTo: dateTo,
                studentId: studentId,
            },
            success: function (response) {
                console.log(response.message);
                if (response.visaStateChanged == true) {
                    window.location.reload();
                }
            },
            error: function (xhr) {
                console.error(xhr.responseText);
            }
        });
    }

    function handleChecklistUpdate() {
        const id = $(this).attr('id').replace(/(student_data_check_list_item_|student_data_check_list_date_from_|student_data_check_list_date_to_)/, '');
        const isChecked = $(`#student_data_check_list_item_${id}`).is(':checked'); // Get the checkbox state
        const dateFrom = $(`#student_data_check_list_date_from_${id}`).val();
        const dateTo = $(`#student_data_check_list_date_to_${id}`).val();
        updateCheckList(id, isChecked, dateFrom, dateTo);
    }

    $('.service-check-list .form-group input[type="checkbox"]').on('change', handleChecklistUpdate);
    $('.service-check-list .date-range-group input.datepicker, .date-range input.datepicker').on('change', handleChecklistUpdate);
});